<template>
    <nav>
        <div class="itens">
            <div class="item planos">
                <span>Planos</span>
            </div>
            <div class="item entrar" @click="goLogin">
                <span>Entrar</span>
            </div>
        </div>

    </nav>
</template>

<script>
    export default {
        methods:{
            goCreateAccount(){
                this.$router.push('/auth/register')
            },
            goLogin(){
                this.$router.push('/auth')
            }
        }
    }
</script>

<style  scoped>
nav{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    color: white;
}


.itens{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.itens>div{
    margin: 0 .5rem;
}
.entrar{
    font-weight: 200;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.entrar:hover{
    font-weight: 400;
}
.btn-criar-conta{
    font-weight: 200;
    border: 1px solid;
    padding: .2rem 1rem;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out;
}
.btn-criar-conta:hover{
    background: white;
    color: var(--principal-azul);
}

</style>