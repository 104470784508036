<template>
  <div class="escolha-perfil">
    <div class="forma-azul">
      <NavBar />

      <div class="textos-capa">
        <div class="title">
          <span>
            Selecione o seu melhor Perfil
            <span class="negrito">Kristal Connect</span></span
          >
        </div>
        <div class="descricao-capa">
          <p>
            Se você deseja cuidar do seu negócio e fazer suas análises, o seu
            perfil é o Investidor. Mas se você buscar ser um profissional que
            atua nos investimentos e análise de diversos investidores, o Perfil
            Assessor é o ideal para sua jornada.
          </p>
        </div>
      </div>

      <div class="cards">
        <Card>
          <div class="texts-card">
            <div class="title-card">
              <span> Perfil <span class="colored">Investidor</span> </span>
            </div>

            <div class="sub-title-card">
              <span> Investir em carteiras </span>
            </div>

            <div class="description-card">
              <span>
                Como Investidor eu gerencio apenas a minha carteira. Com acesso
                apenas uma conta.
              </span>
            </div>
          </div>
          <div class="vantagens-plano">
            <ul>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Uma carteira com diversos planos e possibilidades</span>
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check " src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Quantidade de produtos por carteira de 5 até Ilimitado</span>
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check " src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Até 480 produtos no geral</span>
              </li>
            </ul>
          </div>
          <div id="investidor" class="btn-card orange" @click="goToSignatures($event)">
              <span>Escolher esse perfil</span>
          </div>
        </Card>
        <Card>
          <div class="texts-card">
            <div class="title-card">
              <span> Perfil <span class="colored-blue">Assessor</span> </span>
            </div>

            <div class="sub-title-card">
              <span> Gerenciar das carteiras </span>
            </div>

            <div class="description-card">
              <span>
                Como Assessor Financeiro eu gerencio as carteiras de diversos investidores. Com acesso a um número de contas.
              </span>
            </div>
          </div>
          <div class="vantagens-plano">
            <ul>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check-blue" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Gerencio de 2 até 50 carteiras</span>
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check-blue" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Quantidade de produtos por carteira de 5 até Ilimitado</span>
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check-blue " src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Até 480 produtos no geral</span>
              </li>
            </ul>
          </div>
          <div id="acessor" class="btn-card blue" @click="goToSignatures($event)">
              <span>Escolher esse perfil</span>
          </div>
        </Card>
      </div>

      <div class="observacao">
          <span>
              Os valores apresentados de carteiras e quantidade de produtos por carteira varia de acordo com o plano escolhido
          </span>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Card from "./components/CardBase.vue";
export default {
  components: {
    NavBar,
    Card,
  },
  methods:{
      goToSignatures(el){

            let hasToken = sessionStorage.getItem('token')
            let description = el.currentTarget.id
            let accountId;

            if(hasToken){

              if(description == 'investidor'){ accountId = 0 }
              if(description == 'acessor'){ accountId = 1 }

              let accountType = {type: description, id: accountId}

              this.$store.dispatch('PUT_PROFILE_TYPE', accountType)
              // this.$router.push('/selecao-de-assinatura')
              this.$router.push('cadastro')
            }else{

              if(description == 'investidor'){ accountId = 0 }
              if(description == 'acessor'){ accountId = 1 }

              let accountType = {type: description, id: accountId}

              this.$store.dispatch('PUT_PROFILE_TYPE', accountType)
              this.$router.push('cadastro')
            }


      },
  }
};
</script>

<style  scoped>
.forma-azul {
  height: 654px;
  background: var(--principal-azul);
  position: relative;
}
.textos-capa {
  margin-top: 1.5rem;
  text-align: center;
  color: white;
  max-width: 750px;
  margin: 0 auto;
}
.textos-capa .title span {
  font-weight: 300;
  font-size: 1.4em;
  line-height: 60px;
  letter-spacing: 0.15px;
}
.textos-capa .title .negrito {
  font-weight: 600;
}
.textos-capa .descricao-capa {
  margin-top: 1.5rem;
}
.textos-capa .descricao-capa p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
}

.cards{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 3rem auto;
}

/* .texts-card{
} */

.title-card{
   font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
  margin:  .3rem 0;
}
.title-card .colored{
    color: #F3A03C;
    font-weight: 700;
}
.title-card .colored-blue{
    font-weight: 700;
    color: var(--principal-azul);
}
.sub-title-card{
    font-weight: 600;
    margin: .3rem 0;
}
.description-card{
    font-weight: 300;
    margin: 1rem 0;
    font-size: .9em;
}

.vantagens-plano {
  margin: 1.5rem 0;
}
.vantagens-plano ul {
  padding: 0 !important;
}
.vantagens-plano ul li {
  list-style: none;
  list-style: none;
  padding: 0.5rem 0;
  display: flex;
}
.vantagens-plano ul li span {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.vantagem-item .img-check {
  width: 15px;
  height: 15px;
  margin-right: 0.7rem;
}
.check {
filter: invert(23%) sepia(49%) saturate(1535%) hue-rotate(332deg) brightness(102%) contrast(91%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.check-blue{
    filter: invert(63%) sepia(52%) saturate(4286%) hue-rotate(192deg) brightness(101%) contrast(90%);
     width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-card{

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-radius: 20px;
  margin: 3.5rem 0;
  cursor: pointer;
  position: absolute;
  bottom: -35px;
  width: 90%;
  left: 17px;
}
.orange{
    border: 1px solid #F3A03C;
    background: none !important;
    color: #F3A03C;
    transition: .2s ease-in-out;
}
.orange:hover{
    background: #F3A03C !important;
    color: white;
}

.blue{
      border: 1px solid var(--principal-azul);
    background: none !important;
    color: var(--principal-azul);
    transition: .2s ease-in-out;
}
.blue:hover{
     background: var(--principal-azul) !important;
    color: white;
}

.observacao{
    text-align: center;
    padding: .5rem;
}
</style>