var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "escolha-perfil" }, [
    _c(
      "div",
      { staticClass: "forma-azul" },
      [
        _c("NavBar"),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "cards" },
          [
            _c("Card", [
              _c("div", { staticClass: "texts-card" }, [
                _c("div", { staticClass: "title-card" }, [
                  _c("span", [
                    _vm._v(" Perfil "),
                    _c("span", { staticClass: "colored" }, [
                      _vm._v("Investidor"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "sub-title-card" }, [
                  _c("span", [_vm._v(" Investir em carteiras ")]),
                ]),
                _c("div", { staticClass: "description-card" }, [
                  _c("span", [
                    _vm._v(
                      " Como Investidor eu gerencio apenas a minha carteira. Com acesso apenas uma conta. "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "vantagens-plano" }, [
                _c("ul", [
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [
                      _vm._v(
                        "Uma carteira com diversos planos e possibilidades"
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [
                      _vm._v(
                        "Quantidade de produtos por carteira de 5 até Ilimitado"
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Até 480 produtos no geral")]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "btn-card orange",
                  attrs: { id: "investidor" },
                  on: {
                    click: function ($event) {
                      return _vm.goToSignatures($event)
                    },
                  },
                },
                [_c("span", [_vm._v("Escolher esse perfil")])]
              ),
            ]),
            _c("Card", [
              _c("div", { staticClass: "texts-card" }, [
                _c("div", { staticClass: "title-card" }, [
                  _c("span", [
                    _vm._v(" Perfil "),
                    _c("span", { staticClass: "colored-blue" }, [
                      _vm._v("Assessor"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "sub-title-card" }, [
                  _c("span", [_vm._v(" Gerenciar das carteiras ")]),
                ]),
                _c("div", { staticClass: "description-card" }, [
                  _c("span", [
                    _vm._v(
                      " Como Assessor Financeiro eu gerencio as carteiras de diversos investidores. Com acesso a um número de contas. "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "vantagens-plano" }, [
                _c("ul", [
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check-blue",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Gerencio de 2 até 50 carteiras")]),
                  ]),
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check-blue",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [
                      _vm._v(
                        "Quantidade de produtos por carteira de 5 até Ilimitado"
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "vantagem-item" }, [
                    _c(
                      "div",
                      { staticClass: "img-check" },
                      [
                        _c("v-img", {
                          staticClass: "check-blue",
                          attrs: {
                            src: require("@/assets/icons/check_icon.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Até 480 produtos no geral")]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "btn-card blue",
                  attrs: { id: "acessor" },
                  on: {
                    click: function ($event) {
                      return _vm.goToSignatures($event)
                    },
                  },
                },
                [_c("span", [_vm._v("Escolher esse perfil")])]
              ),
            ]),
          ],
          1
        ),
        _vm._m(1),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "textos-capa" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [
          _vm._v(" Selecione o seu melhor Perfil "),
          _c("span", { staticClass: "negrito" }, [_vm._v("Kristal Connect")]),
        ]),
      ]),
      _c("div", { staticClass: "descricao-capa" }, [
        _c("p", [
          _vm._v(
            " Se você deseja cuidar do seu negócio e fazer suas análises, o seu perfil é o Investidor. Mas se você buscar ser um profissional que atua nos investimentos e análise de diversos investidores, o Perfil Assessor é o ideal para sua jornada. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "observacao" }, [
      _c("span", [
        _vm._v(
          " Os valores apresentados de carteiras e quantidade de produtos por carteira varia de acordo com o plano escolhido "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }