<template>
    <v-card class="card"> 
        <slot/>
    </v-card>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .card {
  width: 300px;
  height: 455px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px !important;
  margin-top: 1.5rem;
  position: relative;
  padding: 2rem 1.5rem;
  position: relative;
}
</style>